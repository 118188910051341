import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { isUserLoggedIn } from "../../api/auth";
import { getReceipts, toggleChecked } from "../../api/receipt";
import { read, writeFile } from 'xlsx';
import { Disclosure } from '@headlessui/react';
import { MinusIcon, PlusIcon } from '@heroicons/react/20/solid';
import { BallTriangle } from 'react-loader-spinner';

export default function Challan() {
    let navigate = useNavigate();

    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const day = String(currentDate.getDate()).padStart(2, '0');
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;

    const [history, setHistory] = useState([]);
    const [orgHistory, setOrgHistory] = useState([]);
    const [sumGrandTotal, setSumGrandTotal] = useState(0);
    const [sumPaid, setSumPaid] = useState(0);
    const [sumToPay, setSumToPay] = useState(0);
    const [fromDate, setFromDate] = useState(formattedDate);
    const [toDate, setToDate] = useState();
    const [from, setFrom] = useState("");
    const [to, setTo] = useState("");
    const [fCategory, setFCategory] = useState([]);
    const [selectedR, setSelectedR] = useState([]);
    const [Loader, setLoader] = useState(true);
    const [checkedItems, setCheckedItems] = useState({}); 
    const [checkedStateChanged, setCheckedStateChanged] = useState(false); 

    // Function to toggle the checked state of the checkboxes
    const toggleCheckbox = (sectionId, optionIdx) => {
        setCheckedItems((prevCheckedItems) => {
            const updatedCheckedItems = { ...prevCheckedItems };
            updatedCheckedItems[sectionId] = { ...updatedCheckedItems[sectionId] };
            updatedCheckedItems[sectionId][optionIdx] = !updatedCheckedItems[sectionId][optionIdx];
            return updatedCheckedItems;
        });
    };

    useEffect(() => {
        const checkUserLoggedIn = async () => {
            const loggedIn = await isUserLoggedIn();
            if (!loggedIn) {
                toast.error("Please Login First");
                navigate("/login");
            }
        };
        checkUserLoggedIn();
    }, [navigate]);

    async function modifyExcelFile() {
        const f = await fetch('./challan.xlsx');
        const data = await f.arrayBuffer();
        const wb = read(data, { type: 'array' });
        const ws = wb.Sheets[wb.SheetNames[0]];
        let row = 0;
        if(selectedR.length !== 0){
            selectedR.forEach((rec, index) => {
                row = index + 14;
                ws[`A${row}`] = { t: 's', v: index + 1 };
                ws[`B${row}`] = { t: 's', v: rec.no };
                ws[`C${row}`] = { t: 's', v: rec.to };
                ws[`D${row}`] = { t: 's', v: rec.pkgs };
                ws[`E${row}`] = { t: 's', v: rec.consignor };
                ws[`G${row}`] = { t: 's', v: rec.consignee };
                ws[`J${row}`] = { t: 's', v: rec.total_freight.paid };
                ws[`K${row}`] = { t: 's', v: rec.total_freight.toPay };
                ws[`L${row}`] = { t: 's', v: rec.total_freight.grandTotal };
                ws[`M${row}`] = { t: 's', v: '' };
            });
        }else{
            history.forEach((rec, index) => {
                row = index + 14;
                ws[`A${row}`] = { t: 's', v: index + 1 };
                ws[`B${row}`] = { t: 's', v: rec.receipt.no };
                ws[`C${row}`] = { t: 's', v: rec.receipt.to };
                ws[`D${row}`] = { t: 's', v: rec.receipt.pkgs };
                ws[`E${row}`] = { t: 's', v: rec.receipt.consignor };
                ws[`G${row}`] = { t: 's', v: rec.receipt.consignee };
                ws[`J${row}`] = { t: 's', v: rec.receipt.total_freight.paid };
                ws[`K${row}`] = { t: 's', v: rec.receipt.total_freight.toPay };
                ws[`L${row}`] = { t: 's', v: rec.receipt.total_freight.grandTotal };
                ws[`M${row}`] = { t: 's', v: '' };
            });
        }
        ws[`G${row + 2}`] = { t: 's', v: 'Total' };
        ws[`J${row + 2}`] = { t: 's', v: sumPaid };
        ws[`K${row + 2}`] = { t: 's', v: sumToPay };
        ws[`L${row + 2}`] = { t: 's', v: sumGrandTotal };
        writeFile(wb, "challan.xlsx");
    }

    const filters = [
        {
            id: 'category',
            name: 'Category',
            options: [
                { value: 'Electronic', label: 'Electronic', checked: false },
                { value: 'Food', label: 'Food', checked: false },
                { value: 'Clothing', label: 'Clothing', checked: false },
                { value: 'Other', label: 'Other', checked: false },
            ],
        },
    ]

    const [arr, setArr] = useState([]);
    async function filter(value) {
        let arr1 = [...fCategory];

        if (fCategory.indexOf(value) === -1) {
            arr1.push(value);
            setArr(arr1);
            setFCategory(arr1);
        } else {
            arr1.splice(fCategory.indexOf(value), 1);
            setArr(arr1);
            setFCategory(arr1);
        }
    }

    const handleCheckboxChange = (item) => {
        const isSelected = selectedR.some((selected) => selected.no === item.no);
        if (isSelected) {
            setSelectedR(selectedR.filter((selected) => selected.no !== item.no));
        } else {
            setSelectedR([...selectedR, item]);
        }
    };

    const updateCheckStatus = async (id, checked, receipt) => {
        await toggleChecked(id, !checked); 
        setCheckedStateChanged(true);
        await handleCheckboxChange(receipt);
    };

    const printChallan = () => {
        window.print();
    };

    useEffect(() => {
        const filterFunction = async () => {
            setHistory([]);
            setLoader(true);
            await getReceipts(arr, fromDate, toDate, from, to)
                .then((res) => {
                    setHistory(res.data);
                    setOrgHistory(res.data);
                    setLoader(false);
                })
                .catch((err) => console.log(err));
        };
        filterFunction();
        setCheckedStateChanged(false);
    }, [arr, fromDate, toDate, from, to, checkedStateChanged]);

    function search(value) {
        console.log(orgHistory);
        const filteredHistory = orgHistory.filter((item) => {
            console.log(item.receipt);
            return item.receipt.no.toString().includes(value);
        });
        setHistory(filteredHistory);
    }

    useEffect(() => {
        if (history.length === 0) {
            setSumGrandTotal(0);
            setSumPaid(0);
            setSumToPay(0);
        } else {
            if (selectedR.length !== 0) {
                setSumGrandTotal(selectedR.reduce((sum, rec) => sum + Number(rec.total_freight.grandTotal), 0));
                setSumPaid(selectedR.reduce((sum, rec) => sum + Number(rec.total_freight.paid), 0));
                setSumToPay(selectedR.reduce((sum, rec) => sum + Number(rec.total_freight.toPay), 0));
            }
            else {
                setSumGrandTotal(history.reduce((sum, rec) => sum + Number(rec.receipt.total_freight.grandTotal), 0));
                setSumPaid(history.reduce((sum, rec) => sum + Number(rec.receipt.total_freight.paid), 0));
                setSumToPay(history.reduce((sum, rec) => sum + Number(rec.receipt.total_freight.toPay), 0));
            }
        }
    }, [history, selectedR]);

    return (
        <>
            <div className="flex gap-28">
                <aside id="sidebar" class="flex flex-col w-64 px-4 overflow-y-auto bg-gray-100 border-r rtl:border-r-0 rtl:border-l border-gray-600 dark:bg-gray-900 dark:border-gray-700">
                    <div class="relative mt-6 mb-4">
                        <span class="absolute inset-y-0 left-0 flex items-center pl-3">
                            <svg class="w-5 h-5 text-gray-400" viewBox="0 0 24 24" fill="none">
                                <path d="M21 21L15 15M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                            </svg>
                        </span>

                        <input onChange={(e) => { search(e.target.value) }} type="text" class="w-full py-2 pl-10 pr-4 text-gray-700 dark:border-gray-200 bg-white border rounded-md dark:bg-gray-900 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 dark:focus:border-blue-300 focus:ring-blue-300 focus:ring-opacity-40 focus:outline-none focus:ring" placeholder="Search" />
                    </div>
                    <section className="my-auto">
                        <div >
                            {/* Filters */}
                            <form className="hidden lg:block">

                                {filters.map((section) => (
                                    <Disclosure as="div" key={section.id} className="border-y border-gray-800 dark:border-gray-200 py-6">
                                        {({ open }) => (
                                            <>
                                                <h3 className="-my-3 flow-root">
                                                    <Disclosure.Button className="flex w-full items-center justify-between py-3 text-sm text-gray-800 dark:text-white hover:text-gray-500">
                                                        <span className="text-base font-semibold hover:text-gray-500">{section.name}</span>
                                                        <span className="ml-6 flex items-center">
                                                            {open ? (
                                                                <MinusIcon className="h-5 w-5" aria-hidden="true" />
                                                            ) : (
                                                                <PlusIcon className="h-5 w-5" aria-hidden="true" />
                                                            )}
                                                        </span>
                                                    </Disclosure.Button>
                                                </h3>
                                                <Disclosure.Panel className="pt-6">
                                                    <div className="space-y-4">
                                                        {section.options.map((option, optionIdx) => (
                                                            <div key={option.value} className="flex items-center">
                                                                <input
                                                                    id={`filter-${section.id}-${optionIdx}`}
                                                                    name={`${section.id}[]`}
                                                                    defaultValue={option.value}
                                                                    type="checkbox"
                                                                    checked={checkedItems[section.id]?.[optionIdx] || false} // Get the checked state from state
                                                                    onChange={() => toggleCheckbox(section.id, optionIdx)} // Use onChange to handle state change
                                                                    onClick={() => filter(option.value)}
                                                                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                                                />
                                                                <label
                                                                    htmlFor={`filter-${section.id}-${optionIdx}`}
                                                                    className="ml-3 text-sm text-gray-700 dark:text-gray-200"
                                                                >
                                                                    {option.label}
                                                                </label>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </Disclosure.Panel>
                                            </>
                                        )}
                                    </Disclosure>
                                ))}
                                <Disclosure as="div" className="border-b border-gray-800 dark:border-gray-200 py-6">
                                    {({ open }) => (
                                        <>
                                            <h3 className="-my-3 flow-root">
                                                <Disclosure.Button className="flex w-full items-center justify-between py-3 text-sm text-gray-800 dark:text-white hover:text-gray-500">
                                                    <span className="text-base font-semibold hover:text-gray-500">Date</span>
                                                    <span className="ml-6 flex items-center">
                                                        {open ? (
                                                            <MinusIcon className="h-5 w-5" aria-hidden="true" />
                                                        ) : (
                                                            <PlusIcon className="h-5 w-5" aria-hidden="true" />
                                                        )}
                                                    </span>
                                                </Disclosure.Button>
                                            </h3>
                                            <Disclosure.Panel className="pt-6">
                                                <div className="space-y-4">
                                                    <div className="flex justify-between items-center">
                                                        <label className="ml-3 text-sm text-gray-700 dark:text-gray-200">
                                                            From
                                                        </label>
                                                        <input type="date" value={fromDate} onChange={(e) => setFromDate(e.target.value)} class="text-center block text-black bg-white border border-gray-400 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring" />
                                                    </div>

                                                    <div className="flex justify-between items-center">
                                                        <label className="ml-3 text-sm text-gray-700 dark:text-gray-200">
                                                            To
                                                        </label>
                                                        <input type="date" value={toDate} onChange={(e) => setToDate(e.target.value)} class="text-center block text-black bg-white border border-gray-400 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring" />
                                                    </div>
                                                </div>
                                            </Disclosure.Panel>
                                        </>
                                    )}
                                </Disclosure>
                                <Disclosure as="div" className="border-b border-gray-800 dark:border-gray-200 py-6">
                                    {({ open }) => (
                                        <>
                                            <h3 className="-my-3 flow-root">
                                                <Disclosure.Button className="flex w-full items-center justify-between py-3 text-sm text-gray-800 dark:text-white hover:text-gray-500">
                                                    <span className="text-base font-semibold hover:text-gray-500">Destination</span>
                                                    <span className="ml-6 flex items-center">
                                                        {open ? (
                                                            <MinusIcon className="h-5 w-5" aria-hidden="true" />
                                                        ) : (
                                                            <PlusIcon className="h-5 w-5" aria-hidden="true" />
                                                        )}
                                                    </span>
                                                </Disclosure.Button>
                                            </h3>
                                            <Disclosure.Panel className="pt-6">
                                                <div className="space-y-4">
                                                    <div className="flex justify-between items-center">
                                                        <label className="ml-3 text-sm text-gray-700 dark:text-gray-200">
                                                            From
                                                        </label>
                                                        <select value={from} onChange={(e) => setFrom(e.target.value)} class="text-center block text-black bg-white border border-gray-400 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring">
                                                            <option selected value=""></option>
                                                            <option value="Hyderabad">Hyderabad</option>
                                                            <option value="Bellary">Bellary</option>
                                                            <option value="Hospet">Hospet</option>
                                                            <option value="Gangavathi">Gangavathi</option>
                                                            <option value="Gadag">Gadag</option>
                                                            <option value="Hubli">Hubli</option>
                                                        </select>
                                                    </div>

                                                    <div className="flex justify-between items-center">
                                                        <label className="ml-3 text-sm text-gray-700 dark:text-gray-200">
                                                            To
                                                        </label>
                                                        <select value={to} onChange={(e) => setTo(e.target.value)} class="text-center block text-black bg-white border border-gray-400 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring">
                                                            <option selected value=""></option>
                                                            <option value="Bellary">Bellary</option>
                                                            <option value="Hospet">Hospet</option>
                                                            <option value="Gangavathi">Gangavathi</option>
                                                            <option value="Gadag">Gadag</option>
                                                            <option value="Hubli">Hubli</option>
                                                            <option value="Hyderabad">Hyderabad</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </Disclosure.Panel>
                                        </>
                                    )}
                                </Disclosure>
                            </form>

                            {/* Product grid */}
                            <div className="lg:col-span-3">{/* Your content */}</div>
                        </div>
                    </section>
                    <div class="relative my-4 flex justify-center">
                        <span class="bottom-0 ">
                            <button onClick={() => navigate("/challan/deleted")} class="px-6 py-2 font-medium tracking-wide capitalize transition-colors duration-300 transform border text-black border-black rounded-lg hover:bg-red-500 hover:text-white focus:outline-none focus:ring focus:ring-red-300 focus:ring-opacity-80">
                                Deleted Receipts
                            </button>
                        </span>
                    </div>
                </aside>

                <div>
                    <p class="pt-8 text-center text-5xl font-mono text-gray-700">Hubli Bellary Roadways</p>
                    <p class="text-center text-2xl text-gray-600">FLEET OWNERS & TRANSPORT CONTRACTOR</p>
                    <p class="text-center text-base text-black dark:text-gray-300 text-gray-700 whitespace-nowrap">Anderal Road Opp: Mullangi Compound, Shamlamareppaa Compound, Bellary</p>
                    <p class="text-center text-base text-black dark:text-gray-300 text-gray-700 whitespace-nowrap">H.O.: #5-2-200/A/17, Beside Ashok Hotel, New Osmanganj, Hyderabad.</p>

                    <p class="max-h-16 text-center mt-6 mx-60 border border-gray-500 text-2xl px-4 bg-white text-black dark:text-gray-300">LORRY HIRE CHALLAN</p>
                    <div id="truckno-drivername" className="flex justify-between my-6 hidden">
                        <td>Truck No.: __________________________</td>
                        <td>Drivers Name: __________________________</td>
                    </div>
                    {/* <p class="text-center text-black dark:text-gray-300 whitespace-nowrap mb-8">Without Transhipment With</p> */}
                    {/* <p class="mx-80 px-14 text-black dark:text-gray-300 whitespace-nowrap">Haulge Contract From</p> */}
                    {/* <div className="flex justify-center">
                <table className="table-auto">
                    <tbody>
                        <tr>
                            <td class="px-4 text-black dark:text-gray-300 whitespace-nowrap">No. :</td>
                            <td class="px-4 text-sm text-black dark:text-gray-300 whitespace-nowrap"><input type="text" placeholder="3556" class="text-center block w-full text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring" disabled /></td>
                            <td class="px-4 text-black dark:text-gray-300 whitespace-nowrap">Date :</td>
                            <td class="px-4 text-sm text-black dark:text-gray-300 whitespace-nowrap"><input type="date" class="text-center block w-full px-14 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring" /></td>
                        </tr>
                        <tr>
                            <td class="px-4 text-black dark:text-gray-300 whitespace-nowrap">Truck No. :</td>
                            <td class="px-4 text-sm text-black dark:text-gray-300 whitespace-nowrap"><input type="text" class="text-center block w-full px-10 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring" /></td>
                            <td class="px-4 text-black dark:text-gray-300 whitespace-nowrap">Driver :</td>
                            <td class="px-4 text-sm text-black dark:text-gray-300 whitespace-nowrap"><input type="text" class="text-center block w-full px-14 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring" /></td>
                        </tr>
                        <tr>
                            <td class="px-4 text-black dark:text-gray-300 whitespace-nowrap">From :</td>
                            <td class="px-4 text-sm text-black dark:text-gray-300 whitespace-nowrap"><select class="text-center block w-full text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring">
                                <option value="HYB">Hyderabad</option>
                                <option value="BLR">Bellary</option>
                                <option value="PUNE">Pune</option>
                            </select></td>
                            <td class="px-4 text-black dark:text-gray-300 whitespace-nowrap">To :</td>
                            <td class="px-4 text-sm text-black dark:text-gray-300 whitespace-nowrap"><select class="text-center block w-full text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring">
                                <option value="BLR">Bellary</option>
                                <option value="HYB">Hyderabad</option>
                                <option value="PUNE">Pune</option>
                            </select></td>
                        </tr>

                        <tr>
                            <td class="px-4 text-black dark:text-gray-300 whitespace-nowrap">Owner :</td>
                            <td class="px-4 text-sm text-black dark:text-gray-300 whitespace-nowrap" colSpan={3}><input type="text" class="block w-full text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring" /></td>
                        </tr>

                    </tbody>
                </table>
            </div> */}

                    <div className="flex justify-center mt-6 mb-2">

                        <table className="bg-white dark:bg-gray-900">
                            <tbody>
                                <tr>
                                    <td class="border-t border-l border-gray-500 px-4 text-black dark:text-gray-300 whitespace-nowrap text-center">S.R.</td>
                                    <td class="border border-gray-500 px-4 text-black dark:text-gray-300 whitespace-nowrap text-center" rowSpan={2}>G.C. No.</td>
                                    <td class="border-t px-4 border-gray-500 text-black dark:text-gray-300 whitespace-nowrap text-center">To</td>
                                    <td class="border border-gray-500 px-4 text-black dark:text-gray-300 whitespace-nowrap text-center" rowSpan={2} >Pkgs.</td>
                                    <td class="border border-gray-500 px-4 text-black dark:text-gray-300 whitespace-nowrap text-center" rowSpan={2} >Consignor</td>
                                    <td class="border border-gray-500 px-4 text-black dark:text-gray-300 whitespace-nowrap text-center" rowSpan={2} >Consignee</td>
                                    <td class="border border-gray-500 px-4 text-black dark:text-gray-300 whitespace-nowrap text-center" colSpan={3}>TOTAL FREIGHT</td>
                                    {/* <td class="border-t border-r border-gray-500 px-4 text-black dark:text-gray-300 whitespace-nowrap text-center">Service</td> */}
                                    <td class="border-y border-r border-gray-500 px-4 text-black dark:text-gray-300 whitespace-nowrap text-center" rowSpan={2}>Mark</td>
                                    {/* <td class="border-y border-r border-gray-500 px-4 text-black dark:text-gray-300 whitespace-nowrap text-center" rowSpan={2}>Print</td> */}
                                </tr>

                                <tr>
                                    <td class="border-l border-gray-500 px-4 border-b text-center text-black dark:text-gray-300 whitespace-nowrap">No.</td>
                                    <td class="border-b border-gray-500 px-4 text-black dark:text-gray-300 whitespace-nowrap">Station</td>
                                    <td class="border border-gray-500 px-4 text-black dark:text-gray-300 whitespace-nowrap">Paid</td>
                                    <td class="border border-gray-500 px-4 text-black dark:text-gray-300 whitespace-nowrap">To Pay</td>
                                    <td class="border border-gray-500 px-5 text-black dark:text-gray-300 whitespace-nowrap">To be Billed</td>
                                    {/* <td class="border-b border-r border-gray-500 px-4 text-black dark:text-gray-300 whitespace-nowrap">Remark</td> */}
                                </tr>


                                <div className="absolute left-1/2">
                                    <BallTriangle color="#4338CA" height={80} width={80} visible={Loader} />
                                </div>

                                {history.length === 0 ? (
                                    <tr>
                                        <td colspan="10" class="border border-gray-500 px-4 py-2 text-center text-sm text-black dark:text-gray-300">
                                            No records found.
                                        </td>
                                    </tr>
                                ) : (
                                    history.map((rec, index) => (
                                        <tr className={rec.receipt.checked ? "checked-receipt" : "unchecked-receipt"}>
                                            <td class="border border-gray-500 px-4 text-center text-sm text-black dark:text-gray-300 whitespace-nowrap">{index + 1}</td>
                                            <td class="border border-gray-500 px-4 text-center text-sm text-black dark:text-gray-300 whitespace-nowrap underline hover:text-blue-600 hover:no-underline" onClick={() => { navigate(`/receipt/${rec.id}`) }}>{rec.receipt.no}</td>
                                            <td class="border border-gray-500 px-4 text-center text-sm text-black dark:text-gray-300 whitespace-nowrap">{rec.receipt.to}</td>
                                            <td class="border border-gray-500 px-4 text-center text-sm text-black dark:text-gray-300 whitespace-nowrap">{rec.receipt.pkgs}</td>
                                            <td class="border border-gray-500 px-4 text-center text-sm text-black dark:text-gray-300 whitespace-nowrap">{rec.receipt.consignor}</td>
                                            <td class="border border-gray-500 px-4 text-center text-sm text-black dark:text-gray-300 whitespace-nowrap">{rec.receipt.consignee}</td>
                                            <td class="border border-gray-500 px-4 text-center text-sm text-black dark:text-gray-300 whitespace-nowrap">{rec.receipt.total_freight.paid}</td>
                                            <td class="border border-gray-500 px-4 text-center text-sm text-black dark:text-gray-300 whitespace-nowrap">{rec.receipt.total_freight.toPay}</td>
                                            <td class="border border-gray-500 px-4 text-center text-sm text-black dark:text-gray-300 whitespace-nowrap">{rec.receipt.total_freight.grandTotal}</td>
                                            {/* <td class="border border-gray-500 px-4 text-center text-sm text-black dark:text-gray-300 whitespace-nowrap"></td> */}
                                            <td class="border border-gray-500 pb-1 text-center text-sm text-black dark:text-gray-300 whitespace-nowrap"><div className="mark-checkbox"><input type="checkbox" checked={rec.receipt.checked} onChange={() => updateCheckStatus(rec.id, rec.receipt.checked, rec.receipt)}></input></div></td>
                                            <td class="hidden border border-gray-500 pb-1 text-center text-sm text-black dark:text-gray-300 whitespace-nowrap empty-cell"></td>
                                            {/* <td class="border border-gray-500 pb-1 text-center text-sm text-black dark:text-gray-300 whitespace-nowrap"><input type="checkbox" onChange={() => {setCheckedStateChanged(true); handleCheckboxChange(rec.receipt);}} checked={selectedR.some((selected) => selected.no === rec.receipt.no)}></input></td> */}
                                        </tr>
                                    )))}

                                <tr>
                                    <td class="px-4 text-sm text-black dark:text-gray-300 whitespace-nowrap bg-gray-100 dark:bg-gray-900" colSpan={5}></td>
                                    <td class="border border-gray-500 px-4 text-sm text-black dark:text-gray-300 whitespace-nowrap text-center">TOTAL</td>
                                    <td class="border border-gray-500 px-4 text-center text-sm text-black dark:text-gray-300 whitespace-nowrap">{sumPaid}</td>
                                    <td class="border border-gray-500 px-4 text-center text-sm text-black dark:text-gray-300 whitespace-nowrap">{sumToPay}</td>
                                    <td class="border border-gray-500 px-4 text-center text-sm text-black dark:text-gray-300 whitespace-nowrap">{sumGrandTotal}</td>
                                    <td class="bg-gray-100 dark:bg-gray-900" colspan={2}></td>
                                </tr>

                            </tbody>
                        </table>

                    </div>
                    {/* <div className="mr-96">
                <div className="flex justify-between items-center mt-10 pl-4 pr-96 mr-96">
                    <   p class="text-center text-small text-black dark:text-gray-300 whitespace-nowrap">Loaded By</p>
                    <p class="text-center text-small text-black dark:text-gray-300 whitespace-nowrap">Drivers's Signature</p>
                    <p class="text-center text-small text-black dark:text-gray-300 whitespace-nowrap">Booking Clerk</p>
                </div>
            </div> */}

                    <div className="flex justify-center my-8 gap-12">
                        <button id="excel-sheet-button" onClick={() => modifyExcelFile()} class="px-6 py-2 font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-red-600 rounded-lg hover:bg-red-500 focus:outline-none focus:ring focus:ring-red-300 focus:ring-opacity-80">
                            Excel Sheet
                        </button>
                        <button onClick={() => printChallan()} class="px-6 py-2 font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-green-600 rounded-lg hover:bg-green-500 focus:outline-none focus:ring focus:ring-green-300 focus:ring-opacity-80">
                            Print Challan
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}
