import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { isUserLoggedIn } from "../../api/auth";
import { getDeletedReceipts, restoreReceiptAPI} from "../../api/receipt";
import { BallTriangle } from 'react-loader-spinner';

export default function DeletedChallan() {
    let navigate = useNavigate();

    const [history, setHistory] = useState([]);
    const [Loader, setLoader] = useState(true);

    useEffect(() => {
        const checkUserLoggedIn = async () => {
            const loggedIn = await isUserLoggedIn();
            if (!loggedIn) {
                toast.error("Please Login First");
                navigate("/login");
            }
            else {
                await getDeletedReceipts()
                    .then((res) => {
                        setHistory(res.data);
                        setLoader(false);
                    })
                    .catch((err) => console.log(err));
            }
        };

        checkUserLoggedIn();
    }, [navigate]);

    async function restoreReceipt(id) {
        await restoreReceiptAPI(id);
        toast.success("Receipt Restored Successfully");
        window.location.reload();
    }

    return (
        <>
            <div>
                <p class="pt-8 text-center text-5xl font-mono text-gray-700">Hubli Bellary Roadways</p>
                <p class="text-center text-2xl text-gray-600">FLEET OWNERS & TRANSPORT CONTRACTOR</p>
                <p class="text-center text-base text-black dark:text-gray-300 text-gray-700 whitespace-nowrap">Anderal Road Opp: Mullangi Compound, Shamlamareppaa Compound, Bellary</p>
                <p class="text-center text-base text-black dark:text-gray-300 text-gray-700 whitespace-nowrap">H.O.: #5-2-200/A/17, Beside Ashok Hotel, New Osmanganj, Hyderabad.</p>

                <p class="text-center mt-6 mx-96 border border-gray-500 text-2xl px-4 bg-white text-black dark:text-gray-300">DELETED RECIEPTS</p>

                <div className="flex justify-center pt-6 pb-14">

                    <table className="bg-white dark:bg-gray-900">
                        <tbody>
                            <tr>
                                <td class="border-t border-l border-gray-500 px-4 text-black dark:text-gray-300 whitespace-nowrap text-center">S.R.</td>
                                <td class="border border-gray-500 px-4 text-black dark:text-gray-300 whitespace-nowrap text-center" rowSpan={2}>G.C. No.</td>
                                <td class="border-t px-4 border-gray-500 text-black dark:text-gray-300 whitespace-nowrap text-center">To</td>
                                <td class="border border-gray-500 px-4 text-black dark:text-gray-300 whitespace-nowrap text-center" rowSpan={2} >Pkgs.</td>
                                <td class="border border-gray-500 px-4 text-black dark:text-gray-300 whitespace-nowrap text-center" rowSpan={2} >Consignor</td>
                                <td class="border border-gray-500 px-4 text-black dark:text-gray-300 whitespace-nowrap text-center" rowSpan={2} >Consignee</td>
                                <td class="border border-gray-500 px-4 text-black dark:text-gray-300 whitespace-nowrap text-center" colSpan={3}>TOTAL FREIGHT</td>
                                <td class="border-y border-r border-gray-500 px-4 text-black dark:text-gray-300 whitespace-nowrap text-center" rowSpan={2}>Restore</td>
                            </tr>

                            <tr>
                                <td class="border-l border-gray-500 px-4 border-b text-center text-black dark:text-gray-300 whitespace-nowrap">No.</td>
                                <td class="border-b border-gray-500 px-4 text-black dark:text-gray-300 whitespace-nowrap">Station</td>
                                <td class="border border-gray-500 px-4 text-black dark:text-gray-300 whitespace-nowrap">Paid</td>
                                <td class="border border-gray-500 px-4 text-black dark:text-gray-300 whitespace-nowrap">To Pay</td>
                                <td class="border border-gray-500 px-5 text-black dark:text-gray-300 whitespace-nowrap">To be Billed</td>
                            </tr>


                            <div className="absolute left-1/2">
                                <BallTriangle color="#4338CA" height={80} width={80} visible={Loader} />
                            </div>

                            {history.length === 0 ? (
                                <tr>
                                    <td colspan="10" class="border border-gray-500 px-4 py-2 text-center text-sm text-black dark:text-gray-300">
                                        No records found.
                                    </td>
                                </tr>
                            ) : (
                                history.map((rec, index) => (
                                    <tr>
                                        <td class="border border-gray-500 px-4 text-center text-sm text-black dark:text-gray-300 whitespace-nowrap">{index + 1}</td>
                                        <td class="border border-gray-500 px-4 text-center text-sm text-black dark:text-gray-300 whitespace-nowrap">{rec.receipt.no}</td>
                                        <td class="border border-gray-500 px-4 text-center text-sm text-black dark:text-gray-300 whitespace-nowrap">{rec.receipt.to}</td>
                                        <td class="border border-gray-500 px-4 text-center text-sm text-black dark:text-gray-300 whitespace-nowrap">{rec.receipt.pkgs}</td>
                                        <td class="border border-gray-500 px-4 text-center text-sm text-black dark:text-gray-300 whitespace-nowrap">{rec.receipt.consignor}</td>
                                        <td class="border border-gray-500 px-4 text-center text-sm text-black dark:text-gray-300 whitespace-nowrap">{rec.receipt.consignee}</td>
                                        <td class="border border-gray-500 px-4 text-center text-sm text-black dark:text-gray-300 whitespace-nowrap">{rec.receipt.total_freight.paid}</td>
                                        <td class="border border-gray-500 px-4 text-center text-sm text-black dark:text-gray-300 whitespace-nowrap">{rec.receipt.total_freight.toPay}</td>
                                        <td class="border border-gray-500 px-4 text-center text-sm text-black dark:text-gray-300 whitespace-nowrap">{rec.receipt.total_freight.grandTotal}</td>
                                        <td class="border border-gray-500 pb-1 text-center text-sm text-black dark:text-gray-300 whitespace-nowrap"><button className="text-red-500" onClick={() => restoreReceipt(rec.id)}>Restore</button></td>
                                    </tr>
                                )))}

                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}
