import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { isUserLoggedIn } from "../../api/auth";

export default function Profile() {
  let navigate = useNavigate();
  const [profileImg, setProfileImg] = useState();

  const SignOut = async () => {
      document.cookie = 'authToken=; Expires=Thu, 01 Jan 1970 00:00:00 UTC; Secure; SameSite=Strict';
      toast.success("logged out successfully");
      navigate("/");
  };

  useEffect(() => {
    const checkUserLoggedIn = async () => {
      const loggedIn = await isUserLoggedIn();
      if (loggedIn) {
        const str = "Hubli Bellary Roadways";
        if (str.indexOf(" ") !== -1) {
          setProfileImg(
            (str[0] + str[str.indexOf(" ") + 1]).toUpperCase().toString()
          );
        } else {
          setProfileImg(str[0].toUpperCase().toString());
        }
      } else {
        toast.error("Please Login First");
        navigate("/login");
      }
    };
  
    checkUserLoggedIn();
  }, [navigate]);  

  return (
    <>
      <div className="block py-24 bg-gray-100 dark:bg-gray-700">
        <div className="max-w-2xl mx-auto">
          <div className="bg-white dark:bg-gray-800 border border-primary rounded-b-lg p-5 flex flex-col">
          <div
                className="mx-auto bg-gray-200 border border-gray-300 h-36 w-40 rounded-lg shadow-md border-b border-primary mb-4"
                id="profileimg"
              >
                {profileImg}
              </div>
            <div className="mx-auto mb-1 text-3xl font-mono text-gray-800" id="name">
              Hubli Bellary Roadways
            </div>
            <div className="mx-auto mb-1 text-xl text-gray-600">
            FLEET OWNERS & TRANSPORT CONTRACTOR
            </div>
            <button className="mt-3 text-white bg-red-600 hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800" onClick={SignOut}>SignOut</button>
          </div>
        </div>
      </div>
    </>
  );
}
