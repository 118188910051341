import React from 'react';
import ReactDOM from "react-dom";
import './index.css';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Footer from './components/common/Footer';
import Navbar from './components/common/Navbar';
import Home from './components/homepage/Home';
import Login from './components/user/Login';
import ForgotPass from './components/user/ForgotPass';
import Profile from './components/user/Profile';
import PageNotFound from './components/common/404';
import Receipt from './components/main/Reciept';
import Challan from './components/main/Challan';
import GetReceipt from './components/main/GetReceipt';
import DeletedChallan from './components/main/DeletedChallan';
// import Truck from './components/main/Truck';
// import AddTruck from './components/main/AddTruck';

export default function App() {
  return (
    <>
    <div className='bg-white dark:bg-gray-900'>
    <Navbar/>
    <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
          <div className='bg-gray-100 dark:bg-gray-900'>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/forgetpassword" element={<ForgotPass />} />
          <Route path="/receipt" element={<Receipt />} />
          {/* <Route path="/truck" element={<Truck />} /> */}
          {/* <Route path="/truck/add" element={<AddTruck />} /> */}
          <Route path="/receipt/:id" element={<GetReceipt />} />
          <Route path="/challan" element={<Challan />} />
          <Route path="/challan/deleted" element={<DeletedChallan />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </BrowserRouter>
          </div>
    <Footer/>
    </div>
    </>
  );
}

ReactDOM.render(<React.StrictMode>
  <App />
</React.StrictMode>,
document.getElementById('root'));