import React from "react";

export default function Navbar() {
    return (
        <>
            <nav id="navbar" className="sticky top-0 z-50 shadow border-b border-slate-900/10 dark:border-slate-300/10">
      <div className="container backdrop-filter backdrop-blur-lg px-6 py-4 mx-auto md:flex md:justify-between md:items-center">
        <div className="flex items-center justify-between">
                        <a className="text-3xl font-mono text-gray-800" href="/">Hubli Bellary Roadways</a>
                    </div>

                    <div className="flex items-center">
                        <a href="/receipt" className="mx-3 mt-2 border-b-2 border-transparent hover:text-gray-800 transition-colors duration-300 transform dark:hover:text-gray-200 hover:border-blue-500">Reciept</a>
                        <a href="/challan" className="mx-3 mt-2 border-b-2 border-transparent hover:text-gray-800 transition-colors duration-300 transform dark:hover:text-gray-200 hover:border-blue-500">Challan</a>
                        {/* <a href="/truck" className="mx-3 mt-2 border-b-2 border-transparent hover:text-gray-800 transition-colors duration-300 transform dark:hover:text-gray-200 hover:border-blue-500">Truck</a> */}
                        <div className="ml-6 flex items-center mt-1">
                            <a href="/profile" type="button" className="flex items-center focus:outline-none" aria-label="toggle profile dropdown">
                                <div className="w-8 h-8 overflow-hidden dark:bg-gray-400 border-2 border-gray-400 rounded-full">
                                    <img src="https://upload.wikimedia.org/wikipedia/commons/9/99/Sample_User_Icon.png" alt="user" />
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </nav>
        </>
    );
}