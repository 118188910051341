import { initializeApp } from "firebase/app";
import { getAuth,GoogleAuthProvider } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAIoJFzZLKHjavrFh61UAtIS_KNnqHzaPI",
  authDomain: "hbroad-a8cf4.firebaseapp.com",
  projectId: "hbroad-a8cf4",
  storageBucket: "hbroad-a8cf4.appspot.com",
  messagingSenderId: "698731138317",
  appId: "1:698731138317:web:ff76a195e72c2362193444"
};

  const app = initializeApp(firebaseConfig);

  export const auth=getAuth(app);
  export const googleAuthProvider=new GoogleAuthProvider();