import axios from "axios";

export const isUserLoggedIn = async () => {
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith('authToken=')) {
      const token = cookie.substring('authToken='.length);

      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/validateToken`, { token });
      if (response.status === 200) {
        return true;
      }
    }
  }
  return false;
};
