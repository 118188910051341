import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { isUserLoggedIn } from "../../api/auth";
import { saveReceiptApi, getReceiptNo } from "../../api/receipt";

export default function Receipt() {
    let navigate = useNavigate();

    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const day = String(currentDate.getDate()).padStart(2, '0');
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;

    const [no, setNo] = useState(0);
    const [truckNo, setTruckNo] = useState("");
    const [date, setDate] = useState(formattedDate);
    const [from, setFrom] = useState("Hyderabad");
    const [to, setTo] = useState("");
    const [otherTo, setOtherTo] = useState("");
    const [consignor, setConsignor] = useState("");
    const [consignee, setConsignee] = useState("");
    const [category, setCategory] = useState("");
    const [phoneNo, setPhoneNo] = useState("");

    const [pkgs, setPkgs] = useState("");
    const [description, setDescription] = useState("");
    const [weight, setWeight] = useState("");
    const [freight, setFreight] = useState("");
    const [statical, setStatical] = useState("");
    const [hamali, setHamali] = useState("");
    const [total, setTotal] = useState("");
    const [toPay, setToPay] = useState("");
    const [paid, setPaid] = useState("");
    const [serviceTax, setServiceTax] = useState("");
    const [grandTotal, setGrandTotal] = useState("");
    const [goodsValue, setGoodsValue] = useState("");

    async function reset() {
        window.location.reload();
    }

    async function saveReceipt(e) {
        e.preventDefault();
        var too = to;
        if (to === "Other") {
            too = otherTo;
        }
        let receipt = {
            "no": no,
            "truckNo": truckNo,
            "date": date,
            "from": from,
            "to": too,
            "consignor": consignor,
            "consignee": consignee,
            "pkgs": pkgs,
            "description": description,
            "weight": weight,
            "category": category,
            "phoneNo": phoneNo,
            "total_freight": {
                "freight": freight,
                "statical": statical,
                "hamali": hamali,
                "total": total,
                "serviceTax": serviceTax,
                "grandTotal": grandTotal,
                "toPay": toPay,
                "paid": paid
            },
            "goodsValue": goodsValue,
            "checked": false
        }
        await saveReceiptApi(receipt)
            .then((res) => {
                // window.location.reload();
                toast.success("Receipt Saved");
                document.getElementById("saveButton").style.display = "none";
            })
            .catch((err) => {
                console.log(err);
                toast.error("Error Saving Reciept");
            });
    }

    const printReceipt = () => {
        var too = to;
        if (to === "Other") {
            too = otherTo;
        }
        let receipt = {
            "no": no,
            "truckNo": truckNo,
            "date": date,
            "from": from,
            "to": too,
            "consignor": consignor,
            "consignee": consignee,
            "pkgs": pkgs,
            "description": description,
            "category": category,
            "weight": weight,
            "total_freight": {
                "freight": freight,
                "statical": statical,
                "hamali": hamali,
                "total": total,
                "serviceTax": serviceTax,
                "grandTotal": grandTotal,
                "toPay": toPay,
                "paid": paid
            },
            "goodsValue": goodsValue
        }
        const receiptJSON = JSON.stringify(receipt);
        const receiptBase64 = btoa(receiptJSON);
        window.open(`printReceipt.html?receipt=${encodeURIComponent(receiptBase64)}`, '_blank');
    };

    useEffect(() => {
        const checkUserLoggedIn = async () => {
            const loggedIn = await isUserLoggedIn();
            if (!loggedIn) {
                toast.error("Please Login First");
                navigate("/login");
            }
            else {
                const NO = await getReceiptNo();
                setNo(Number(NO.data) + 1)
            }
        };

        checkUserLoggedIn();
    }, [navigate]);

    useEffect(() => {
        let sum = Number(freight) + Number(statical) + Number(hamali);
        if (sum > 0) {
            setTotal(sum)
        };
    }, [freight, statical, hamali])

    useEffect(() => {
        let sum = Number(total) + Number(serviceTax);
        if (sum > 0) {
            setGrandTotal(sum);
            setToPay(sum);
        }
    }, [total, serviceTax])

    useEffect(() => {
        let sub = Number(grandTotal) - Number(paid);
        if (sub >= 0) {
            setToPay(grandTotal - paid);
        }
    }, [paid, grandTotal])

    useEffect(() => {
        if (to === "Other") {
            document.getElementById("toDropdown").style.display = 'inline-block';
        } else {
            document.getElementById("toDropdown").style.display = 'none';
        }
    }, [to])

    return (
        <>
            <div>
                <p class="pt-8 text-center text-5xl font-mono text-gray-700">Hubli Bellary Roadways</p>
                <p class="text-center text-2xl text-gray-600">FLEET OWNERS & TRANSPORT CONTRACTOR</p>
                <p class="text-center text-base text-black dark:text-gray-300 text-gray-700 whitespace-nowrap">Anderal Road Opp: Mullangi Compound, Shamlamareppaa Compound, Bellary</p>
                <p class="text-center text-base text-black dark:text-gray-300 text-gray-700 whitespace-nowrap">H.O.: #5-2-200/A/17, Beside Ashok Hotel, New Osmanganj, Hyderabad.</p>

                <form onSubmit={(e) => saveReceipt(e)}>
                    <div className="flex justify-center mt-8">
                        <table className="table-auto">
                            <tbody>
                                <tr>
                                    <td class="px-4 text-black text-bold dark:text-gray-300 whitespace-nowrap">No. :</td>
                                    <td class="px-4 text-sm text-black dark:text-gray-300 whitespace-nowrap" colSpan={3}><input type="number" value={no} onChange={(e) => setNo(e.target.value)} class="text-center block w-full text-black bg-white border border-gray-400 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring" required/></td>
                                </tr>
                                <tr>
                                    <td class="px-4 text-black text-bold dark:text-gray-300 whitespace-nowrap">Truck No. :</td>
                                    <td class="px-4 text-sm text-black dark:text-gray-300 whitespace-nowrap"><input value={truckNo} onChange={(e) => setTruckNo(e.target.value)} type="text" class="text-center block w-full px-10 text-black bg-white border border-gray-400 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"/></td>
                                    <td class="px-4 text-black text-bold dark:text-gray-300 whitespace-nowrap">Date :</td>
                                    <td class="px-4 text-sm text-black dark:text-gray-300 whitespace-nowrap"><input type="date" value={date} onChange={(e) => setDate(e.target.value)} class="text-center block w-full px-14 text-black bg-white border border-gray-400 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"/></td>
                                </tr>
                                <tr>
                                    <td class="px-4 text-black text-bold dark:text-gray-300 whitespace-nowrap">From :</td>
                                    <td class="px-4 text-sm text-black dark:text-gray-300 whitespace-nowrap"><select value={from} onChange={(e) => setFrom(e.target.value)} class="text-center block w-full text-black bg-white border border-gray-400 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring" required>
                                        <option value="Hyderabad">Hyderabad</option>
                                        <option value="Bellary">Bellary</option>
                                        <option value="Hospet">Hospet</option>
                                        <option value="Gangavathi">Gangavathi</option>
                                        <option value="Gadag">Gadag</option>
                                        <option value="Hubli">Hubli</option>
                                    </select></td>
                                    <td class="px-4 text-black text-bold dark:text-gray-300 whitespace-nowrap">To :</td>
                                    <td class="px-4 text-sm text-black dark:text-gray-300 whitespace-nowrap"><select value={to} onChange={(e) => setTo(e.target.value)} class="text-center block w-full text-black bg-white border border-gray-400 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring" required>
                                        <option disabled selected value=""></option>
                                        <option value="Bellary">Bellary</option>
                                        <option value="Hospet">Hospet</option>
                                        <option value="Gangavathi">Gangavathi</option>
                                        <option value="Gadag">Gadag</option>
                                        <option value="Hubli">Hubli</option>
                                        <option value="Hyderabad">Hyderabad</option>
                                        <option value="Other">Other</option>
                                    </select></td>
                                </tr>
                                <tr>
                                    <td class="px-4 text-black text-bold dark:text-gray-300 whitespace-nowrap" colSpan={3}></td>
                                    <td id="toDropdown" className="hidden px-4 text-sm text-black dark:text-gray-300 whitespace-nowrap"><input value={otherTo} onChange={(e) => setOtherTo(e.target.value)} type="text" class="text-center block w-full px-10 text-black bg-white border border-gray-400 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"/></td>
                                </tr>
                                <tr>
                                    <td class="px-4 text-black text-bold dark:text-gray-300 whitespace-nowrap">Consignor :</td>
                                    <td class="px-4 text-sm text-black dark:text-gray-300 whitespace-nowrap" colSpan={3}><input value={consignor} onChange={(e) => setConsignor(e.target.value)} type="text" class="block w-full text-black bg-white border border-gray-400 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"/></td>
                                </tr>
                                <tr>
                                    <td class="px-4 text-black text-bold dark:text-gray-300 whitespace-nowrap">Consignee :</td>
                                    <td class="px-4 text-sm text-black dark:text-gray-300 whitespace-nowrap" colSpan={3}><input value={consignee} onChange={(e) => setConsignee(e.target.value)} type="text" class="block w-full text-black bg-white border border-gray-400 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"/></td>
                                </tr>
                                <tr>
                                    <td class="px-4 text-black text-bold dark:text-gray-300 whitespace-nowrap">Phone No. :</td>
                                    <td class="px-4 text-sm text-black dark:text-gray-300 whitespace-nowrap" colSpan={3}><input value={phoneNo} onChange={(e) => setPhoneNo(e.target.value)} type="text" minLength={10} maxLength={10} pattern="[0-9]*" class="block w-full text-black bg-white border border-gray-400 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"/></td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                    <p class="text-center text-sm px-4 py-2 text-black  text-bold dark:text-gray-300 line-clamp-2 mx-96" colSpan={10}>Booked Owner's risk carriers are not responsible for leakage breakage, damage, shortage and any Govt. Penalty & Other extra charges.</p>

                    <div className="flex justify-center mt-6 mb-2">

                        <table>
                            <tbody className="bg-white dark:bg-gray-900">
                                <tr>
                                    <td class="border border-gray-500 px-4 text-black dark:text-gray-300 whitespace-nowrap text-center">Pkgs</td>
                                    <td class="border border-gray-500 px-4 text-black dark:text-gray-300 whitespace-nowrap text-center">Description of Goods</td>
                                    <td class="border border-gray-500 px-4 text-black dark:text-gray-300 whitespace-nowrap text-center">Weight</td>
                                    <td class="border border-gray-500 px-4 text-black dark:text-gray-300 whitespace-nowrap text-center">Rate</td>
                                    <td class="border border-gray-500 px-4 text-black dark:text-gray-300 whitespace-nowrap text-center"
                                    // colSpan={2}
                                    >Total Freight</td>
                                    <td class="border border-gray-500 px-4 text-black dark:text-gray-300 whitespace-nowrap text-center">Service Tax Payable By</td>
                                </tr>


                                <tr>
                                    <td class="border border-gray-500 text-sm text-black dark:text-gray-300 whitespace-nowrap" rowSpan={6}><textarea value={pkgs} onChange={(e) => setPkgs(e.target.value)} class="block w-20 h-44 resize bg-white dark:border-gray-900 border-white text-black focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring dark:bg-gray-900 dark:text-gray-300"></textarea></td>
                                    <td class="border border-gray-500 text-sm text-black dark:text-gray-300 whitespace-nowrap" rowSpan={6}><textarea value={description} onChange={(e) => setDescription(e.target.value)} class="block w-48 h-36 resize bg-white border-b dark:border-gray-900 border-white text-black focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring dark:bg-gray-900 dark:text-gray-300"></textarea>
                                        <div className="flex px-2 border-t border-gray-500"><p className="my-1">Category:</p>&nbsp;<select value={category} onChange={(e) => setCategory(e.target.value)} class="text-center block h-8 w-28 text-sm p-0 text-black bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring">
                                            <option disabled selected value=""></option>
                                            <option value="Electronic">Electronic</option>
                                            <option value="Food">Food</option>
                                            <option value="Clothing">Clothing</option>
                                            <option value="Other">Other</option>
                                        </select></div></td>
                                    <td class="border border-gray-500 text-sm text-black dark:text-gray-300 whitespace-nowrap" rowSpan={6}><textarea value={weight} onChange={(e) => setWeight(e.target.value)} class="block w-20 h-44 resize dark:border-gray-900 bg-white border-white text-black focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring dark:bg-gray-900 dark:text-gray-300"></textarea></td>
                                    <td class="border border-gray-500 px-4 text-black dark:text-gray-300 whitespace-nowrap">Freight</td>
                                    <td class="border border-gray-500 text-sm text-black dark:text-gray-300 whitespace-nowrap"><input value={freight} onChange={(e) => setFreight(e.target.value)} className="resize overflow-auto h-6 w-26 text-center bg-white text-black focus:border-blue-500 focus:ring-blue-400 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring dark:bg-gray-900 dark:text-gray-300"></input></td>
                                    {/* <td class="border text-sm text-black dark:text-gray-300 whitespace-nowrap"><input className="resize overflow-auto h-6 w-16 bg-white text-gray-700 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40 dark:bg-gray-900 dark:text-gray-300"></input></td> */}
                                    {/* <td class="border text-sm text-black dark:text-gray-300 whitespace-nowrap"><input className="resize overflow-auto h-6 w-48 bg-white text-gray-700 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40 dark:bg-gray-900 dark:text-gray-300"></input></td> */}
                                    <td class="border border-gray-500 text-sm text-black dark:text-gray-300 whitespace-nowrap"></td>
                                </tr>

                                <tr>
                                    <td class="border border-gray-500 px-4 text-black dark:text-gray-300 whitespace-nowrap">Statical</td>
                                    <td class="border border-gray-500 text-sm text-black dark:text-gray-300 whitespace-nowrap"><input value={statical} onChange={(e) => setStatical(e.target.value)} className="resize overflow-auto h-6 w-26 text-center bg-white text-black focus:border-blue-500 focus:ring-blue-400 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring dark:bg-gray-900 dark:text-gray-300"></input></td>
                                    {/* <td class="border text-sm text-black dark:text-gray-300 whitespace-nowrap"><input className="resize overflow-auto h-6 w-16 bg-white text-gray-700 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40 dark:bg-gray-900 dark:text-gray-300"></input></td> */}
                                    <td class="border border-gray-500 text-sm text-black dark:text-gray-300 whitespace-nowrap"></td>
                                    {/* <td class="border text-sm text-black dark:text-gray-300 whitespace-nowrap"><input className="resize overflow-auto h-6 w-48 bg-white text-gray-700 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40 dark:bg-gray-900 dark:text-gray-300"></input></td> */}
                                </tr>

                                <tr>
                                    <td class="border border-gray-500 px-4 text-black dark:text-gray-300 whitespace-nowrap">Hamali</td>
                                    <td class="border border-gray-500 text-sm text-black dark:text-gray-300 whitespace-nowrap"><input value={hamali} onChange={(e) => setHamali(e.target.value)} className="resize overflow-auto h-6 w-26 text-center bg-white text-black focus:border-blue-500 focus:ring-blue-400 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring dark:bg-gray-900 dark:text-gray-300"></input></td>
                                    {/* <td class="border text-sm text-black dark:text-gray-300 whitespace-nowrap"><input className="resize overflow-auto h-6 w-16 bg-white text-gray-700 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40 dark:bg-gray-900 dark:text-gray-300"></input></td> */}
                                    <td class="border border-gray-500 text-sm text-black dark:text-gray-300 whitespace-nowrap"></td>
                                    {/* <td class="border text-sm text-black dark:text-gray-300 whitespace-nowrap"><input className="resize overflow-auto h-6 w-48 bg-white text-gray-700 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40 dark:bg-gray-900 dark:text-gray-300"></input></td> */}
                                </tr>

                                <tr>
                                    <td class="border border-gray-500 px-4 text-black dark:text-gray-300 whitespace-nowrap">Total</td>
                                    <td class="border border-gray-500 text-sm text-black dark:text-gray-300 whitespace-nowrap"><input value={total} onChange={(e) => setTotal(e.target.value)} className="resize overflow-auto h-6 w-26 text-center bg-white text-black focus:border-blue-500 focus:ring-blue-400 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring dark:bg-gray-900 dark:text-gray-300"></input></td>
                                    {/* <td class="border text-sm text-black dark:text-gray-300 whitespace-nowrap"><input className="resize overflow-auto h-6 w-16 bg-white text-gray-700 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40 dark:bg-gray-900 dark:text-gray-300"></input></td> */}
                                    <td class="border border-gray-500 px-4 text-black dark:text-gray-300 whitespace-nowrap">To Pay: <input disabled value={toPay} onChange={(e) => setToPay(e.target.value)} className="resize overflow-auto w-28 text-center bg-white text-black focus:border-blue-500 focus:ring-blue-400 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring dark:bg-gray-900 dark:text-gray-300"></input></td>
                                </tr>

                                <tr>
                                    <td class="border border-gray-500 px-4 text-black dark:text-gray-300 whitespace-nowrap">Service Tax</td>
                                    <td class="border border-gray-500 text-sm text-black dark:text-gray-300 whitespace-nowrap"><input value={serviceTax} onChange={(e) => setServiceTax(e.target.value)} className="resize overflow-auto h-6 w-26 text-center bg-white text-black focus:border-blue-500 focus:ring-blue-400 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring dark:bg-gray-900 dark:text-gray-300"></input></td>
                                    {/* <td class="border text-sm text-black dark:text-gray-300 whitespace-nowrap"><input className="resize overflow-auto h-6 w-16 bg-white text-gray-700 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40 dark:bg-gray-900 dark:text-gray-300"></input></td> */}
                                    <td class="text-center border-gray-500 border pl-4 text-black dark:text-gray-300 whitespace-nowrap">Paid: <input value={paid} onChange={(e) => setPaid(e.target.value)} className="resize overflow-auto w-28 text-center bg-white text-black focus:border-blue-500 focus:ring-blue-400 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring dark:bg-gray-900 dark:text-gray-300"></input></td>
                                </tr>

                                <tr>
                                    <td class="border border-gray-500  px-4 text-black dark:text-gray-300 whitespace-nowrap">Grand Total</td>
                                    <td class="border border-gray-500 text-sm text-black dark:text-gray-300 whitespace-nowrap"><input disabled value={grandTotal} onChange={(e) => setGrandTotal(e.target.value)} className="resize overflow-auto h-6 w-26 text-center bg-white text-black focus:border-blue-500 focus:ring-blue-400 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring dark:bg-gray-900 dark:text-gray-300"></input></td>
                                    {/* <td class="border text-sm text-black dark:text-gray-300 whitespace-nowrap"><input className="resize overflow-auto h-6 w-16 bg-white text-gray-700 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40 dark:bg-gray-900 dark:text-gray-300"></input></td> */}
                                    <td class="text-center border border-gray-500 px-4 text-black dark:text-gray-300 whitespace-nowrap">To be Billed</td>
                                </tr>
                            </tbody>
                        </table>

                    </div>

                    <div className="flex justify-center items-center mb-2">
                        <p class="text-center text-small text-black dark:text-gray-300 whitespace-nowrap">Value of Goods -</p>
                        <input value={goodsValue} onChange={(e) => setGoodsValue(e.target.value)} type="text" class="text-xs block text-black bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"/>
                        <p class="text-center text-small text-black dark:text-gray-300 whitespace-nowrap">. Delivery will be given on submission of the Consignee copy.</p>
                    </div>
                    <p class="text-center text-xs text-black dark:text-gray-300 whitespace-nowrap">Shall declare that goods tendered by me/us do not contain any contraband goods and that the description of the goods</p>
                    <p class="text-center text-xs text-black dark:text-gray-300 whitespace-nowrap">by me/us has correctly entered. I/We have read rule and conditions on the reverse and I/We shall abide by the same.</p>
                    <div className="flex justify-center mt-8 pb-12 gap-12">
                        <button onClick={() => reset()} class="px-6 py-2 font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-yellow-400 rounded-lg hover:bg-yellow-300 focus:outline-none focus:ring focus:ring-red-300 focus:ring-opacity-80">
                            Reset Receipt
                        </button>
                        <button type="submit" id="saveButton" class="px-6 py-2 font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-600 rounded-lg hover:bg-blue-500 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-80">
                            Save Receipt
                        </button>
                        <button onClick={() => printReceipt()} class="px-6 py-2 font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-green-600 rounded-lg hover:bg-green-500 focus:outline-none focus:ring focus:ring-green-300 focus:ring-opacity-80">
                            Print Receipt
                        </button>
                    </div>
                </form>

            </div>
        </>
    );
}
