import React from "react";

export default function Footer() {
    return (
        <>
            <footer id="footer" className="bg-white shadow-inner border-t border-white dark:bg-gray-900">
                <div className="container p-6 pb-4 mx-auto">
                    <div className="lg:flex">
                        <div className="w-full -mx-6 lg:w-1/2">
                            <div className="px-6">
                                <div>
                                    <a href="/" className="text-2xl font-mono text-gray-800">Hubli Bellary Roadways</a>
                                </div>

                                <p className="max-w-sm mt-2 text-l text-gray-500">FLEET OWNERS & TRANSPORT CONTRACTOR</p>
                                <p className="max-w-sm mt-2 text-gray-500 dark:text-gray-400">GST No. 36AHBPR9552N3ZK</p>

                                <div className="flex mt-6 -mx-2">
                                    <a href="mailto:kartikvirendrarajput@gmail.com" target="blank"
                                        className="mx-2 text-gray-600 transition-colors duration-300 dark:text-gray-300 hover:text-blue-500 dark:hover:text-blue-400"
                                        aria-label="Mail">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></svg>
                                    </a>
                                    <a href="https://goo.gl/maps/RExoY5aP89VbmGBX6" target="blank"
                                        className="mx-2 text-gray-600 transition-colors duration-300 dark:text-gray-300 hover:text-blue-500 dark:hover:text-blue-400"
                                        aria-label="Map">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="12" cy="10" r="3"/><path d="M12 21.7C17.3 17 20 13 20 10a8 8 0 1 0-16 0c0 3 2.7 6.9 8 11.7z"/></svg>
                                    </a>
                                    <a href="https://goo.gl/maps/RExoY5aP89VbmGBX6" target="blank"
                                        className="mx-2 text-gray-600 transition-colors duration-300 dark:text-gray-300 hover:text-blue-500 dark:hover:text-blue-400"
                                        aria-label="Call">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path></svg>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="mt-6 lg:mt-0 lg:flex-1">
                            <div className="grid grid-cols-2 gap-6 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2">
                                <div>
                                    <h3 className="text-center text-gray uppercase dark:text-white">Locations</h3>
                                    <p className="block mt-2 text-sm text-gray-600 dark:text-gray-400 hover:underline">Anderal Road Opp: Mullangi Compound, Shamlamareppaa Compound, Bellary</p>
                                    <p className="block mt-2 text-sm text-gray-600 dark:text-gray-400 hover:underline">H.O.: #5-2-200/A/17, Beside Ashok Hotel, New Osmangunj, Hyderabad</p>
                                </div>

                                <div className="text-center">
                                    <h3 className="text-gray uppercase dark:text-white">Contact us</h3>
                                    <p className="block mt-2 text-sm text-gray-600 dark:text-gray-400">9989477483</p>
                                    <p className="block mt-2 text-sm text-gray-600 dark:text-gray-400">9032677483</p>
                                    <p className="block mt-2 text-sm text-gray-600 dark:text-gray-400">8977677483</p>
                                    <p className="block mt-2 text-sm text-gray-600 dark:text-gray-400">8121677483</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <hr className="h-px my-4 bg-gray-200 border-none dark:bg-gray-700" />

                    <div>
                        <p className="text-center text-gray dark:text-gray-400">© Hubli Bellary Roadways 2023 - All rights reserved</p>
                    </div>
                </div>
            </footer>
        </>
    );
}