import axios from "axios";

const getToken = () => {
    const cookiesString = document.cookie;
    const cookiesArray = cookiesString.split(';');
    const cookie = cookiesArray.find(cookie => {
        const [name, ] = cookie.split('=').map(c => c.trim());
        return name === 'authToken';
    });
    if (cookie) {
        const [, value] = cookie.split('=').map(c => c.trim());
        return value;
    }
    return null;
};

export const saveReceiptApi = async (receipt) => {
    return await axios.post(`${process.env.REACT_APP_BACKEND_URL}/receipt/save`, {receipt}, {headers: {"Authorization": getToken()}});         
};

export const getReceiptNo = async () => {
    return await axios.get(`${process.env.REACT_APP_BACKEND_URL}/receipt/getNo`, {headers: {"Authorization": getToken()}});         
};

export const getReceipts = async (categories, fromDate, toDate, from, to) => {
    return await axios.post(`${process.env.REACT_APP_BACKEND_URL}/receipt/getAll`, {categories, fromDate, toDate, from, to}, {headers: {"Authorization": getToken()}});
};

export const getDeletedReceipts = async () => {
    return await axios.get(`${process.env.REACT_APP_BACKEND_URL}/receipt/getAllDeleted`, {headers: {"Authorization": getToken()}});
};

export const getReceipt = async (id) => {
    return await axios.post(`${process.env.REACT_APP_BACKEND_URL}/receipt/getReceipt`, {id}, {headers: {"Authorization": getToken()}});         
};

export const updateReceipt = async (id, updatedReceipt) => {
    return await axios.post(`${process.env.REACT_APP_BACKEND_URL}/receipt/updateReceipt`, {id, updatedReceipt}, {headers: {"Authorization": getToken()}});         
};

export const deleteReceiptAPI = async (id) => {
    return await axios.post(`${process.env.REACT_APP_BACKEND_URL}/receipt/deleteReceipt`, {id}, {headers: {"Authorization": getToken()}});         
};

export const restoreReceiptAPI = async (id) => {
    return await axios.post(`${process.env.REACT_APP_BACKEND_URL}/receipt/restoreReceipt`, {id}, {headers: {"Authorization": getToken()}});         
};

// export const saveCheckedReceipt = async (checkedR) => {
//     return await axios.post(`${process.env.REACT_APP_BACKEND_URL}/checkedR/save`, {checkedR});         
// };

// export const getCheckedReceipts = async () => {
//     return await axios.get(`${process.env.REACT_APP_BACKEND_URL}/checkedR/get`);         
// };

export const toggleChecked = async (id, checked) => {
    return await axios.post(`${process.env.REACT_APP_BACKEND_URL}/receipt/setCheckedById`, {id, checked}, {headers: {"Authorization": getToken()}});         
};